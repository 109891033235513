import { memo, useMemo } from "react"
import { SearchResults } from "@components/Search/Results/SearchResults"
import { Box, Container, HStack, Text } from "@chakra-ui/react"
import { ReactifySearchProvider, Sensors, CustomComponent } from "@usereactify/search"
import { CollectionContent } from "./CollectionContent"
import { ListingHeader } from "@app/components/Listing/ListingHeader"
import { CollectionPromotionalCards } from "./CollectionPromotionalCards"
import { SearchSort } from "@components/Search/Sort/SearchSort"
import { SearchFilterTopCollection } from "@components/Search/Filters/SearchFilterTopCollection"
import { CollectionSideBarFiltersDesktop } from "./CollectionSideBarFiltersDesktop"
import { usePageCollection } from "@app/hooks/usePageCollection"
import Sections from "@components/Sections/Sections"

import type { Props } from "@app/templates/collection"
import type { PageProps } from "@root/types/global"

const Collection: React.FC<PageProps<Props>> = ({ collection, template }) => {
  const { getPageCollection } = usePageCollection({ collection })
  const { item, shopifyPermanentDomain, headerContent, bottomContent } = useMemo(() => getPageCollection(), [getPageCollection])

  return (
    <>
      <ReactifySearchProvider
        mode="collection"
        collectionHandle={collection?.shopify?.handle ?? ""}
        shopifyPermanentDomain={shopifyPermanentDomain}
        additionalComponentIds={["REVIEW_PRODUCTS"]}
      >
        <Sensors />
        <CustomComponent
          componentId="REVIEW_PRODUCTS"
          customQuery={() => ({
            query: {
              bool: {
                must_not: [
                  {
                    term: {
                      "tags.keyword": "review-product",
                    },
                  },
                ],
              },
            },
          })}
        />
        <ListingHeader title={item?.title}>
          <Text maxW="970px" mb={4} lineHeight="21px" fontSize="sm" dangerouslySetInnerHTML={{ __html: headerContent }} />
          <SearchFilterTopCollection topCollectionHandle={collection?.headerFilter} />
        </ListingHeader>
        {/* Bg gradient below is a tricky workaround to get left and right box colors to bleed to screen edge properly */}
        <Box bgGradient={["unset", "unset", "linear(to-r, brand.lightSand 0 50%, brand.offWhite 50% 100%)"]}>
          <Container as={HStack} alignItems="stretch" spacing={[0, 0, 4]}>
            <CollectionSideBarFiltersDesktop topCollectionHandle={collection?.headerFilter} />

            <Box py={4} pl={[0, 0, 4]} bg="brand.offWhite" flex={1} maxW="full">
              <CollectionPromotionalCards cards={collection?.promotionalCards} />
              <SearchSort topCollectionHandle={collection?.headerFilter} />
              <SearchResults collection={collection} />
              <CollectionContent content={bottomContent} />
            </Box>
          </Container>
        </Box>
      </ReactifySearchProvider>
      {template?.content?.length && <Sections page={template} />}
    </>
  )
}

export default memo(Collection)
